import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const isAuthenticated = localStorage.getItem('access_token'); // You can retrieve from cookie if stored there
  const [loginText, setLoginText] = React.useState('Login With Patreon');
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleLogin = () => {
    if (isAuthenticated) {
      // Remove access token from localStorage
      localStorage.removeItem('access_token');
      navigate('/');
      setLoginText('Login With Patreon');
    } else {
      //window.location.href = 'http://localhost:5000/login'; // Flask backend URL
      window.location.href = 'https://www.dotyklab.com/login'; // Flask backend URL
    }
  };

  React.useEffect(() => { 
    if (isAuthenticated) {
      setLoginText('Logout');
    }
  }, [isAuthenticated])

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Box sx={{ px: 5 }}>
            <Sitemark />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
              <Button href="/eye-exercise-vertical" variant="text" color="info" size="small">
                  Vertical
              </Button>
              <Button href="/eye-exercise-horizontal" variant="text" color="info" size="small">
                  Horizontal
              </Button>
              <Button href="/eye-exercise-blink" variant="text" color="info" size="small">
                Blink
              </Button>
              <Button href="/eye-exercises-set" variant="text" color="info" size="small">
                  Eye Set
              </Button>
              <Button href="/posture-exercise-set" variant="text" color="info" size="small">
                  Posture Set
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Button href="https://www.patreon.com/dotyklab/membership" variant="text" color="info" size="small">
              Donate
            </Button>
            <Button href="mailto:dotyklab@gmail.com" variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                Contact
            </Button>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem component={"a"} href="/eye-exercise-vertical">Vertical</MenuItem>
                <MenuItem component={"a"} href="/eye-exercise-horizontal" >Horizontal</MenuItem>
                <MenuItem component={"a"} href="/eye-exercise-blink">Blink</MenuItem>
                <MenuItem component={"a"} href="/eye-exercises-set" >Eye Set</MenuItem>
                <MenuItem component={"a"} href="/posture-exercise-set" >Posture Set</MenuItem>
                <MenuItem component={"a"} href="https://www.patreon.com/dotyklab/membership">Donate</MenuItem>
                <MenuItem href="mailto:dotyklab@gmail.com">Contact</MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
