import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MarketingPage from './pages/MarketingPage';
import Exercise3DBlinkPage from './pages/Exercise3DBlinkPage';
import Exercise3DHorizontalPage from './pages/Exercise3DHorizonatlPage';
import Exercise3DVerticalPage from './pages/Exercise3DVerticalPage';
import Exercise3DSet from './pages/Exercise3DSet'

// First deploy
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MarketingPage/>} />
        <Route path="/eye-exercise-vertical" element={<Exercise3DVerticalPage/>} />
        <Route path="/eye-exercise-horizontal" element={<Exercise3DHorizontalPage />}/>
        <Route path="/eye-exercise-blink" element={<Exercise3DBlinkPage/>} />
        <Route path="/eye-exercises-set" element={<Exercise3DSet muscules={0}/>} />
        <Route path="/posture-exercise-set" element={<Exercise3DSet muscules={1}/>} />
        <Route path="*" element={<MarketingPage/>} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
